
// components
import { IonPage, } from '@ionic/vue';
import ThankYouOrderDetails from '@/components/order/ThankYouOrderDetails.vue';

// composables
import { useRoute } from 'vue-router';

export default {
  name: 'ThankYouOrderPage',
  components: { IonPage, ThankYouOrderDetails, },
  setup() {
    const route = useRoute();
    const { orderId, } = route.params; // Order ID
    const { parentPath } = route.query;

    return {
      orderId,
      parentPath,
    }
  },
}
