import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_page_header = _resolveComponent("page-header")!
  const _component_thank_you_order_details = _resolveComponent("thank-you-order-details")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_page_header, {
        parentPath: $setup.parentPath || '/orders'
      }, null, 8, ["parentPath"]),
      _createVNode(_component_thank_you_order_details, { orderId: $setup.orderId }, null, 8, ["orderId"])
    ]),
    _: 1
  }))
}